import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import VueCookies from 'vue-cookies';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Constants from '@/uikit/util/constants';
import { getSessionAuth } from '@/libs/user-token';

import filterDuration from '@/uikit/filters/duration';
import filterCurrency from '@/uikit/filters/currency';
import filterCapitalize from '@/uikit/filters/capitalize';
import filterTimestamp from '@/uikit/filters/timestamp';
import filterPluralize from '@/uikit/filters/pluralize';
import formatFileSize from '@/uikit/filters/filesize';
import directiveTooltip from 'vue-directive-tooltip';

import AppNotifyPlugin from '@/plugins/app-notify-plugin';
import AppCookiesAlertPlugin from '@/plugins/app-cookies-alert-plugin';
import ImagesPlugin from '@/libs/images';

import 'vuetify/dist/vuetify.min.css';
import '@/uikit/styles/styles.scss';
import '@/styles/index.scss';

async function authorizeUser() {
  try {
    const { token, refreshToken, user } = await getSessionAuth();
    if (token && user) {
      store.dispatch('user/signInWithToken', { token, refreshToken, user });
    }
  } catch (error) {
    console.log('error', error);
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.02,
  });
}

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';

Vue.use(filterDuration);
Vue.use(filterCurrency);
Vue.use(filterCapitalize);
Vue.use(filterTimestamp);
Vue.use(filterPluralize);
Vue.use(formatFileSize);
Vue.use(directiveTooltip);
Vue.use(VueCookies);
Vue.use(HighchartsVue);
Highcharts.setOptions(Constants.highchartOptions);
Vue.use(ImagesPlugin);

Vue.use(AppNotifyPlugin, { store, vuetify });
Vue.use(AppCookiesAlertPlugin, { store, vuetify, router });

authorizeUser().then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
